import React from 'react'
import { Switch, Redirect } from 'react-router'
import UsersPage from '../pages/user-management/users-page/users-page'
import { connect } from 'react-redux';
import {checkIsGroupHasContractRoutine, fetchUser} from '../../redux/routines';
import { Loader } from 'semantic-ui-react';
import { User } from '../../common/types/user';
import Header from '../header/header';
import MenuBar from '../menubar/menubar';
import PrivateRoute from './private-route';
import { Role } from './role';
import { history } from '../../utils/history';
import GroupManagementPage from '../pages/group-management';
import './index.scss';
import GroupInfoPage from '../pages/group-management/group-info/group-info';
import CryptoJS from 'crypto-js';
import env from '../../env';
import { State } from '../../common/types/state';
import ReduxToastr from "react-redux-toastr";
import StreamSettingsPage from '../pages/stream-settings-management/stream-settings-page/stream-settings-page';
import ServerAliasesPage from '../pages/server-aliases-management/server-aliases-page/server-aliases-page';
import StatisticsChartsPage from "../pages/new-statistics/statistics-charts-page/statistics-charts-page";
import StatisticsTablePage from "../pages/new-statistics/statistics-table-page/statistics-table-page";
import ContractSpecPage from "../pages/new-statistics/contract-spec-page/contract-spec-page";
import SsoPage from "../pages/sso-management/sso-page/sso-page";
import AnnouncementsPage from "../pages/announcements/announcements-page/announcements-page";

export interface IRouterProps {
    isLoading: boolean;
    currentUser: User;
    checkIsGroupHasContract: (groupId: number) => void;
    fetchUser: () => void;
}

class CustomRouter extends React.Component<IRouterProps> {

    componentDidMount() {
        const indexWithData = history.location.pathname.indexOf('t=');

        if (indexWithData !== -1) {
            const dataFromUrl = history.location.pathname.slice(indexWithData + 2) // 2 is t=
            const decrypted = CryptoJS.AES.decrypt(dataFromUrl, env.hashKey);
            const obj: { accessToken: string } = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
            localStorage.setItem('token', obj.accessToken);
            this.props.fetchUser();

        } else {
            const token = localStorage.getItem('token');
            if (token) {
                this.props.fetchUser();
                return;
            }
            
            const initPath = window.location.href;
            window.location.href = `${env.inplayUrl}/login?redirectUrl=${initPath}`;
        }
    }

    render() {
        return (
            <div className="page-container">
                <MenuBar />
                {
                    this.props.isLoading
                        ? <Loader active />
                        : (
                            <div className="main-content">
                                <Header />
                                <ReduxToastr
                                    timeOut={10000}
                                    newestOnTop={false}
                                    preventDuplicates={true}
                                    position="top-right"
                                    transitionIn="fadeIn"
                                    transitionOut="fadeOut"
                                    progressBar />
                                <Switch>
                                    <PrivateRoute exact path="/users" roles={[Role.ClientAdmin, Role.ClientAnalyst, Role.ClientSeniorAnalyst]} component={UsersPage} />
                                    <PrivateRoute exact path="/group-management/:groupId" roles={[Role.Admin, Role.SuperAdmin]} component={GroupInfoPage} />
                                    <PrivateRoute exact path="/stream-settings-management" roles={[Role.Admin, Role.SuperAdmin]} component={StreamSettingsPage} />
                                    <PrivateRoute exact path="/server-aliases-management" roles={[Role.Admin, Role.SuperAdmin]} component={ServerAliasesPage} />
                                    <PrivateRoute exact path="/group-management" roles={[Role.Admin, Role.SuperAdmin]} component={GroupManagementPage} />
                                    <PrivateRoute exact path="/statistics-charts/:userId" roles={[Role.Admin, Role.SuperAdmin, Role.ClientAdmin]} component={StatisticsChartsPage} />
                                    <PrivateRoute exact path="/statistics-charts/" roles={[Role.Admin, Role.SuperAdmin, Role.ClientAdmin]} component={StatisticsChartsPage} />
                                    <PrivateRoute exact path="/statistics-table/" roles={[Role.Admin, Role.SuperAdmin, Role.ClientAdmin]} component={StatisticsTablePage} />
                                    <PrivateRoute exact path="/usage-data" roles={[Role.Admin, Role.SuperAdmin, Role.ClientAdmin]} component={ContractSpecPage} />
                                    <PrivateRoute exact path="/sso-management" roles={[Role.Admin, Role.SuperAdmin]} component={SsoPage} />
                                    <PrivateRoute exact path="/announcements" roles={[Role.Admin, Role.SuperAdmin]} component={AnnouncementsPage} />
                                    <Redirect from='*' to={this.defaultRedirect()} />
                                </Switch>
                            </div>
                        )
                }
            </div>
        )
    }

    defaultRedirect = (): string => {
        const initPath = new URLSearchParams(window.location.search).get("redirectUrl");

        if (initPath && localStorage.getItem('token')) {
            return window.location.href = initPath;
        }
        else {
            return this.props.currentUser
                ? [Role.ClientAdmin, Role.ClientAnalyst, Role.ClientSeniorAnalyst].includes(this.props.currentUser.role)
                    ? '/users'
                    : '/group-management'
                : '/users';
        }
    }
}

const mapDispatchToProps = {
    checkIsGroupHasContract: checkIsGroupHasContractRoutine,
    fetchUser: fetchUser
};

const mapStateToProps = (state: State) => {
    return {
        isLoading: state.isLoading,
        currentUser: state.user,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomRouter)

